import type { UseFetchOptions } from "nuxt/app";

export async function useApi<T>(
  url: string | (() => string),
  options?: UseFetchOptions<T>
) {
  const res = await useFetch(url, {
    ...options,
    $fetch: useNuxtApp().$api,
  });

  return res.data.value as any;
}
